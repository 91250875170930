import {
  Button,
  Col,
  Descriptions,
  Modal,
  Row,
  Table,
  Tooltip,
  message,
} from 'antd';
import { format, parseISO, addHours } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import React, { useMemo, useRef, useState } from 'react';
import { MdPrint, MdBook } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { FaFileCsv } from 'react-icons/fa';
import downloadFiles from '../../../../utils/downloadFiles';

import { useApi } from '../../../../hooks/useApi';
import { IState } from '../../../../store';
import { EnumTipoLiquidacao } from '../../../Usuario/Info/CardTipoLiquidacao';

type IComponent = {
  id_arquivo_exportacao: string;
  type_login: string;
};

export interface Bill {
  id_bill: string;
  id_status: string;
  code: string;
  digitable: string;
  type: string;
  correlation_id: string;
  recipient_assignor: string;
  recipient_document: string;
  recipient_name: string;
  payer_document: string;
  payer_name: string;
  due_date: any;
  start_payment_hour: number;
  end_payment_hour: number;
  amount_original: number;
  amount_total: number;
  amount_discount: number;
  amount_additional: number;
  authentication_code: string;
  id_settlement_type: number;
  settlement_institution: string;
  settlement_transaction_id: string;
  settled_at: string;
  created_at: string;
  updated_at: string;
  payments: PaymentBill[];
  status: StatusBill;
}

export interface PaymentBill {
  id_payment: string;
  checkout_id: string;
  establishment_id: string;
  id_bill: string;
  debit_id: any;
  id_status: string;
  type: string;
  amount: number;
  fee_amount: number;
  total_amount: number;
  created_at: string;
  updated_at: string;
}

export interface StatusBill {
  id_status: string;
  name: string;
  type: string;
  description: any;
}

export interface ExportFile {
  id_export_file: string;
  establishment_id: string;
  file_code: number;
  type: string;
  name: string;
  reference_date: string;
  file_url: string;
  file_name: string;
  debits_total: number;
  debits_quantity: number;
  payment_date: string;
  settlement_at: string;
  settlement_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  deleted_by: string;
}

type IInfo = {
  arquivo: any;
  boletos: any[];
  usuario: any;
  export_file: ExportFile;
  bills: Bill[];
};

const ArquivoExportacaoPesquisarRelatorio: React.FC<IComponent> = ({
  id_arquivo_exportacao,
  type_login,
}: IComponent) => {
  const userID = useSelector<IState, string>(state => state.user.id_usuario);
  const [openModal, setOpenModal] = useState(false);
  const [info, setInfo] = useState<IInfo | undefined>(undefined);
  const usuario_nome = useSelector<IState, string>(state => state.user.nome);
  const id_establishment = useSelector<IState, string | undefined>(state => state.user.id_estabelecimento);
  const id_user = useSelector<IState, string | undefined>(state => state.user.id_usuario);

  const componentRef = useRef<HTMLDivElement>(null);

  const { fetchRequest, loading } = useApi();

  const title = useMemo(
    () =>
      info?.export_file?.reference_date
        ? `Relatório Fechamento de Caixa - ${format(
          addHours(parseISO(info?.export_file?.reference_date || ''), 3),
          'dd/MM/yyyy',
        )}`
        : 'Relatório Fechamento de Caixa',
    [info],
  );
  const report = useMemo(() => {
    if (!info) {
      return null;
    }

    const data_referente = format(
      addHours(parseISO(info?.export_file?.reference_date || ''), 3),
      'dd/MM/yyyy',
    );
    const criado_em = format(
      addHours(parseISO(info?.export_file?.created_at || ''), 3),
      'dd/MM/yyyy HH:mm:ss',
    );

    const total_boletos = info?.bills?.reduce(
      (prev, boleto) => prev + boleto.amount_total,
      0,
    );

    return (
      <div ref={componentRef}>
        <h1>Relatório Fechamento de Caixa</h1>

        <Descriptions layout="vertical" size="small" bordered>
          <Descriptions.Item label="Código do Arquivo">
            {info?.export_file.file_code}
          </Descriptions.Item>
          <Descriptions.Item label="Data de Pagamento">
            {data_referente}
          </Descriptions.Item>
          <Descriptions.Item label="Gerado Em">{criado_em}</Descriptions.Item>
          <Descriptions.Item label="Solicitado por">
            {usuario_nome}
          </Descriptions.Item>
        </Descriptions>

        <br />

        <Table
          pagination={false}
          dataSource={info?.bills}
          size="small"
          columns={[
            {
              title: 'Ordem',
              dataIndex: 'cod_boleto_arquivo',
              key: 'cod_boleto_arquivo',
            },
            {
              title: 'Código Boleto',
              dataIndex: 'code',
              key: 'code',
            },
            {
              title: 'Liquidação',
              dataIndex: 'id_settlement_type',
              key: 'id_settlement_type',
              render: (item: number) => {
                if (item === EnumTipoLiquidacao.BANCARIO) return 'Bancária';

                return 'Arquivo retorno';
              },
            },
            {
              title: 'Valor',
              dataIndex: 'amount_total',
              key: 'amount_total',
              align: 'right',
              render: (item: number) => dinheiroMask(item),
            },
          ]}
          summary={() => {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={2} />
                  <Table.Summary.Cell index={3} />
                  <Table.Summary.Cell index={4} align="right">
                    {dinheiroMask(total_boletos)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </div>
    );
  }, [info, usuario_nome]);

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    pageStyle: `padding: 15px;width: 794px;`,
    documentTitle: title,
  });

  function handleOpenModal() {
    if (info) {
      return setOpenModal(true);
    }

    return fetchRequest({
      method: 'post',
      url: `/backoffice/establishment/${id_establishment}/file/report`,
      data: {
        id_export_file: id_arquivo_exportacao,
      },
      onSuccess: data => {
        setInfo(data);
        setOpenModal(true);
      },
      onError: error => {
        if (error.message) {
          message.error(error.message);
        }
      },
    });
  }

  function handlePrintReport() {
    if (handlePrint) {
      handlePrint();
    }
  }

  async function downloadFile(type = 'csv') {
    Modal.info({
      title: 'A exportação foi feita com sucesso!',
      centered: true,
      onOk() {
        return downloadFiles(
          `/backoffice/establishment/${id_establishment}/file/${id_user}/${info?.export_file.id_export_file}`,
          `exportacao_relatorio.${type}`,
          'CRLF',
          {
            id_export_file: id_arquivo_exportacao,
            id_user: userID,
          },
        );
      },
    });
  }

  return (
    <>
      <Button onClick={() => handleOpenModal()} loading={loading}>
        <MdBook />
      </Button>

      <Modal
        // title={title}
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        centered
        footer={false}
        width="800px"
      >
        <Row gutter={8} justify="start">
          <Col md={2}>
            <Button type="primary" onClick={() => handlePrintReport()}>
              <MdPrint />
            </Button>
          </Col>
          <Col md={2}>
            <Tooltip title="Exportar para CSV">
              <Button
                onClick={() => downloadFile()}
                type="primary"
                icon={<FaFileCsv />}
              />
            </Tooltip>
          </Col>
        </Row>

        <br />
        <br />

        {report}
      </Modal>
    </>
  );
};

export default ArquivoExportacaoPesquisarRelatorio;
